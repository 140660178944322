<template>
  <div class="mt-4">
    <FloatLabelInput
      :label="t('customer.company-name')"
      v-model:value="companyNameComputed"
      id="company-name"
      data-testid="company-name"
      :maxlength="500"
      :focusInput="focusInput"
      :isRequired="true"
      :isInvalid="val.$error"
    />
  </div>
  <small class="p-error ml-6" v-if="val.$error" data-testid="company-name-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  companyName: string;
  focusInput?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:companyName", value: string): void;
  (event: "blur", value: string): void;
}>();

const companyNameComputed = computed<string>({
  get: () => {
    return props.companyName ?? "";
  },
  set: (value) => {
    emit("update:companyName", value);
  },
});

const rules = {
  companyName: { required },
};

const val = useVuelidate(rules, props);
</script>
