<template>
  <div class="mt-2">
    <label class="text-base"
      >{{ t("customer.freight-agreements.label") }}
      <i class="pi true-icon pi-question-circle" v-tooltip="t('customer.freight-agreements.tooltip')"></i>
    </label>
  </div>
  <DataTable
    :value="freightAgreementsComputed"
    stripedRows
    dataKey="id"
    responsiveLayout="scroll"
    data-testid="customer-freight-agreements"
    class="c-compact-datatable mx-6"
  >
    <Column field="freightMethods">
      <template #body="{ data }">
        <Select
          id="agreement-freight-method"
          :options="freightMethods"
          v-model="data.freightMethodId"
          optionLabel="name"
          optionValue="id"
          class="w-full"
          :placeholder="
            t('placeholder.select', { property: t('customer.freight-agreements.freight-method').toLowerCase() })
          "
          @update:modelValue="updateFreightAgreement"
          @show="isActive = true"
          @hide="isActive = false"
          @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
        />
      </template>
    </Column>

    <Column field="agreement">
      <template #body="{ data }">
        <Select
          id="agreement-option"
          :options="agreementOptions"
          v-model="data.agreement"
          optionLabel="name"
          class="w-full"
          :placeholder="t('placeholder.select', { property: t('customer.freight-agreements.label').toLowerCase() })"
          @update:modelValue="updateFreightAgreement"
          @show="isActive = true"
          @hide="isActive = false"
          @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
        />
      </template>
    </Column>

    <Column field="agreementPrice">
      <template #body="{ data }">
        <InputNumber
          v-model="data.agreementPrice"
          id="freight-agreement-price"
          class="w-full c-min-width"
          :min="0"
          :placeholder="t('placeholder.type', { property: t('customer.agreement-price').toLowerCase() })"
          @update:modelValue="updateFreightAgreement"
          @focus="selectAllOnFocus"
        />
      </template>
    </Column>

    <Column field="startDate">
      <template #body="{ data }">
        <FreightAgreementDate
          v-model:date="data.startDate"
          id="freight-agreement-start-date"
          @updateFreightAgreement="updateFreightAgreement"
          :isEndDate="false"
        />
      </template>
    </Column>

    <Column field="endDate">
      <template #body="{ data }">
        <FreightAgreementDate
          v-model:date="data.endDate"
          id="freight-agreement-end-date"
          @updateFreightAgreement="updateFreightAgreement"
          :isEndDate="true"
        />
      </template>
    </Column>

    <Column class="text-center">
      <template #body="{ data }">
        <PrimeButton
          id="freight-agreement-delete"
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="onConfirmDelete($event, data)"
        />
      </template>
    </Column>
    <template #empty>{{ t("customer.freight-agreements.no-agreements-found") }}</template>
  </DataTable>
  <ConfirmPopup data-testid="delete-freight-agreement-confirm"></ConfirmPopup>
  <PrimeButton
    type="button"
    class="p-button-text p-button-sm c-add-freight"
    data-testid="btn-add-freight-agreement"
    @click="addFreightAgreement"
    id="add-freight-agreement-button"
    @keydown="handleKeyPress"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </PrimeButton>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, nextTick, ref } from "vue";
import cloneDeep from "lodash.clonedeep";

import FreightAgreementDate from "./FreightAgreementDate.vue";

import { FreightAgreement } from "@/models/customer/FreightAgreement";
import { Agreement } from "@/models/customer/Agreement";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const props = defineProps<{
  freightAgreements: FreightAgreement[];
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (e: "update:freightAgreements", value: FreightAgreement[]): void;
}>();

const { t } = useI18n();

const isActive = ref(false);

const agreementOptions = ref<Agreement[]>([
  {
    id: "5c489c85-4728-4a8c-ab02-3db67a64769b",
    name: t("customer.freight-agreements.fixed-freight"),
  },
]);

const addFreightAgreement = () => {
  const newFreightAgreement = new FreightAgreement();
  freightAgreementsComputed.value.push(newFreightAgreement);
  emit("update:freightAgreements", freightAgreementsComputed.value);
};

const updateFreightAgreement = () => {
  emit("update:freightAgreements", freightAgreementsComputed.value);
};

const deleteFreightAgreement = (freightAgreement: FreightAgreement) => {
  const freightAgreements = freightAgreementsComputed.value.filter((x) => x.id !== freightAgreement.id);
  emit("update:freightAgreements", freightAgreements);
  document.getElementById("add-freight-agreement-button")?.focus();
};

const selectAllOnFocus = (event: Event) => {
  (event.target as HTMLInputElement).select();
};

const freightAgreementsComputed = computed<FreightAgreement[]>(() => {
  return props.freightAgreements.length !== 0 ? props.freightAgreements.map((fa) => cloneDeep(fa)) : [];
});

const onConfirmDelete = (event: Event, data: FreightAgreement) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      deleteFreightAgreement(data);
    },
  });
};

const handleKeyPress = (event: KeyboardEvent) => {
  const elementBtnComit = document.getElementById("btn-commit") as HTMLButtonElement;
  const elementBtnCancel = document.getElementById("btn-cancel");
  const elementBtnContact = document.getElementById("add-contact-button");

  if (event.key === "Tab" && event.shiftKey === false && elementBtnContact != null) {
    nextTick(() => elementBtnContact?.focus());
    event.preventDefault();
  } else {
    if (elementBtnComit?.disabled) {
      nextTick(() => elementBtnCancel?.focus());
    } else {
      nextTick(() => elementBtnComit?.focus());
    }
    event.preventDefault();
  }
};
</script>

<style scoped lang="scss">
.c-min-width {
  min-width: 10rem;
}

:deep(.p-datatable-thead) {
  display: none;
}

:deep(.p-datatable-emptymessage) {
  background-color: var(--surface-card) !important;
}
</style>
