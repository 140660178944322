<template>
  <div class="mt-4">
    <FloatLabelInput
      :label="t('customer.first-name')"
      v-model:value="firstNameComputed"
      id="first-name"
      dataTestId="first-name"
      :maxlength="500"
      :isRequired="true"
      :isInvalid="val.$error"
      :focusInput="focusInput"
    />
  </div>

  <small class="p-error ml-6" v-if="val.$error" data-testid="first-name-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  firstName: string;
  focusInput: boolean;
}>();

const emit = defineEmits<{
  (e: "update:firstName", value: string): void;
}>();

const firstNameComputed = computed<string>({
  get: () => {
    return props.firstName ?? "";
  },
  set: (value) => {
    emit("update:firstName", value);
  },
});

const rules = {
  firstName: { required },
};

const val = useVuelidate(rules, props);
</script>
